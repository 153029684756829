import 'assets/styles/app.scss';
import anime from 'animejs';

const loadedComponents = [];

// Function to animate text
function animateText() {
	// Select all elements with the class .animated-title
	var textWrappers = document.querySelectorAll( '.animated-title' );

	// Create an observer instance
	var observer = new IntersectionObserver( ( entries ) => {
		// Loop over the entries
		entries.forEach( ( entry ) => {
			// If the element is in the viewport
			if ( entry.isIntersecting ) {
				// Start the animation
				anime.timeline( { loop: false } )
				     .add( {
					     targets: entry.target.querySelectorAll( '.word' ),
					     translateY: ['100%', 0], // Slide up effect
					     easing: "easeOutExpo",
					     duration: 2000, // Increase the duration to slow down the animation
					     delay: ( el, i ) => 70 * (i + 1)
				     } );

				// Unobserve the element
				observer.unobserve( entry.target );
			}
		} );
	} );

	// Start observing each text wrapper
	textWrappers.forEach( textWrapper => observer.observe( textWrapper ) );
}

function animateType() {
	// Select all elements with the class .animated-type
	const textWrappers = document.querySelectorAll( '.animated-type' );

	// Create an observer instance
	const observer = new IntersectionObserver( entries => {
		// Loop over the entries
		entries.forEach( entry => {
			// If the element is in the viewport
			if ( entry.isIntersecting ) {
				// Get the text from the text wrapper
				const text = entry.target.textContent;

				// Clear the text content
				entry.target.textContent = '';

				// Split the text into an array of characters
				const textArray = text.split( '' );

				// Create a span for each character and append it to the text wrapper
				textArray.forEach( ( char, i ) => {
					const span = document.createElement( 'span' );
					span.textContent = char;
					span.style.opacity = 0;
					entry.target.appendChild( span );

					// Animate the opacity of each span to create a typing effect
					anime( {
						targets: span,
						opacity: 1, // Make the character visible
						duration: 40, // Duration of the animation
						delay: i * 40, // Delay the animation for each character
						easing: 'linear' // Use a linear easing function
					} );
				} );

				// Unobserve the element
				observer.unobserve( entry.target );
			}
		} );
	} );

	// Start observing each text wrapper
	textWrappers.forEach( textWrapper => observer.observe( textWrapper ) );
}

function animateFadeUp() {
	// Select all elements with the class .animated-fade-up
	const elements = document.querySelectorAll( '.animated-fade-up' );

	// Create an observer instance
	const observer = new IntersectionObserver( entries => {
		// Loop over the entries
		entries.forEach( entry => {
			// If the element is in the viewport
			if ( entry.isIntersecting ) {
				// Start the animation
				anime( {
					targets: entry.target,
					translateY: [50, 0], // Start from 100% below the current position
					opacity: [0, 1], // Fade in from 0 to 1
					easing: 'easeOutExpo',
					duration: 2000
				} );

				// Unobserve the element
				observer.unobserve( entry.target );
			}
		} );
	} );

	// Start observing each element
	elements.forEach( element => observer.observe( element ) );
}

function animateFadeRightToLeft() {
	// Select all elements with the class .animated-fade-right-to-left
	const elements = document.querySelectorAll( '.animated-fade-right-to-left' );

	// Create an observer instance
	const observer = new IntersectionObserver( entries => {
		// Loop over the entries
		entries.forEach( entry => {
			// If the element is in the viewport
			if ( entry.isIntersecting ) {
				// Start the animation
				anime( {
					targets: entry.target,
					translateX: [50, 0], // Start from 100% to the right of the current position
					opacity: [0, 1], // Fade in from 0 to 1
					easing: 'easeOutExpo',
					duration: 2000
				} );

				// Unobserve the element
				observer.unobserve( entry.target );
			}
		} );
	} );

	// Start observing each element
	elements.forEach( element => observer.observe( element ) );
}

function animateFadeLeftToRight() {
	// Select all elements with the class .animated-fade-left-to-right
	const elements = document.querySelectorAll('.animated-fade-left-to-right');

	// Create an observer instance
	const observer = new IntersectionObserver(entries => {
		// Loop over the entries
		entries.forEach(entry => {
			// If the element is in the viewport
			if (entry.isIntersecting) {
				// Start the animation
				anime({
					targets: entry.target,
					translateX: [-50, 0], // Start from 100% to the left of the current position
					opacity: [0, 1], // Fade in from 0 to 1
					easing: 'easeOutExpo',
					duration: 2000
				});

				// Unobserve the element
				observer.unobserve(entry.target);
			}
		});
	});

	// Start observing each element
	elements.forEach(element => observer.observe(element));
}

function animateSlideBottomToTop() {
	// Select all elements with the class .animated-slide-bottom-to-top
	const elements = document.querySelectorAll('.animated-slide-bottom-to-top picture');

	// Create an observer instance
	const observer = new IntersectionObserver(entries => {
		// Loop over the entries
		entries.forEach(entry => {
			// If the element is in the viewport
			if (entry.isIntersecting) {
				// Start the animation
				anime({
					targets: entry.target,
					translateY: [100, 0], // Start from 100% to the bottom of the current position
					easing: 'easeOutExpo',
					duration: 2000
				});

				// Unobserve the element
				observer.unobserve(entry.target);
			}
		});
	});

	// Start observing each element
	elements.forEach(element => observer.observe(element));
}

document.addEventListener( 'DOMContentLoaded', async () => {
	/**
	 * Bootstrap the components
	 */
	for ( const dataComponent of document.querySelectorAll( '[data-component]' ) ) {
		let componentName = dataComponent.dataset.component;

		if ( loadedComponents.indexOf( componentName ) === - 1 ) {
			loadedComponents.push( componentName );

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
					);

				component.default();
			} catch ( error ) {
				console.error( 'Loading error: ', error );
			}
		}
	}

	// Call the text animation function
	animateText();
	animateType();
	animateFadeUp();
	animateFadeRightToLeft();
	animateFadeLeftToRight();
	animateSlideBottomToTop();
} );
